@tailwind base;
@tailwind components;

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */

.details,
.show,
.hide:target {
  display: none;
}

.hide:target+.show,
.hide:target~.details {
  display: block;
}

details-dialog {
  position: fixed;
  margin: 10vh auto;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  max-height: 80vh;
  max-width: 90vw;
  width: 448px;
  background-color: #f1f5f8;
}

.details-with-dialog[open]>.dialog--full-screen {
  width: 90%;
  max-height: 90%;
}

.details-with-dialog[open]>summary {
  cursor: default;
}

.details-with-dialog[open]>summary:before {
  content: " ";
  background: rgba(0, 0, 0, 0.3);
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;

}

@tailwind utilities;

@import 'vendor/tiny-slider';

details summary::-webkit-details-marker {
  display: none;
}

summary {
  list-style: none;
}

.collapse {
  display: none;
}

.modal.in {
  @apply fixed z-50 inset-0 overflow-auto flex;
  background-color: rgba(0, 0, 0, 0.6);
}

.alert {
  @apply mb-10
}

.alert-success {
  @apply bg-green-100 border border-green-200 p-4 rounded mb-4
}

.alert-danger {
  @apply bg-red-100 border border-red-200 p-4 rounded mb-4
}

.fade.in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: .25s;
}

.bullet--checked {
  list-style: none;
  padding-left: 0;
}

.bullet--checked li {
  margin-bottom: 15px;
}

.bullet--checked li:before {
  content: '✓';
  margin-right: 15px;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.field_with_errors input {
  @apply border-red-500;
}

@font-face {
  font-display: auto;
}

/* Additional vertical padding used by kbd tag. */
.py-05 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.markdown {
  @apply text-gray-800 leading-normal break-words;
}

.markdown>*+* {
  @apply mt-0 mb-4;
}

.markdown li+li {
  @apply mt-1;
}

.markdown li>p+p {
  @apply mt-6;
}

.markdown strong {
  @apply font-semibold;
}

.markdown a {
  @apply text-blue-500 font-semibold;
}

.markdown strong a {
  @apply font-bold;
}

.markdown h1 {
  @apply leading-tight border-b text-4xl font-semibold mb-4 mt-6 pb-2;
}

.markdown h2 {
  @apply leading-tight border-b text-2xl font-semibold mb-4 mt-6 pb-2;
}

.markdown h3 {
  @apply leading-loose text-lg font-semibold mb-4 mt-6;
}

.markdown h4 {
  @apply leading-none text-base font-semibold mb-4 mt-6;
}

.markdown h5 {
  @apply leading-tight text-sm font-semibold mb-4 mt-6;
}

.markdown h6 {
  @apply leading-tight text-sm font-semibold text-gray-600 mb-4 mt-6;
}

.markdown blockquote {
  @apply text-base border-l-4 border-gray-500 pl-4 pr-4 text-gray-800;
}

.markdown code {
  @apply font-mono text-sm inline bg-gray-500 rounded px-1 py-05;
}

.markdown pre {
  @apply bg-gray-600 rounded p-4;
}

.markdown pre code {
  @apply block bg-transparent p-0 overflow-visible rounded-none;
}

.markdown ul {
  @apply text-base pl-8;
}

.markdown ol {
  @apply text-base pl-8;
}

.markdown kbd {
  @apply text-xs inline-block rounded border px-1 py-05 align-middle font-normal font-mono shadow;
}

.markdown table {
  @apply text-base border-gray-800;
}

.markdown th {
  @apply border py-1 px-3;
}

.markdown td {
  @apply border py-1 px-3;
}

/* Override pygments style background color. */
.markdown .highlight pre {
  @apply bg-gray-500;
}

.super-select {
  @apply relative;
}

.super-select-button {
  @apply text-gray-800 w-9/10 mt-4 mr-8 text-left py-2 px-3 border bg-white rounded shadow leading-tight;
}

.super-select-placeholder {
  @apply text-black;
}

.super-select-dropdown {
  @apply bg-white my-2 shadow border rounded absolute right-0 left-0 z-50 p-3;
}

.super-select-input {
  @apply block mb-2 w-full border bg-white rounded p-2;
}

.super-select-input:focus {
  @apply outline-none;
}

.super-select-options {
  @apply p-0 relative overflow-y-auto;

  max-height: 25rem;
}

.super-select-option {
  @apply text-black px-3 py-2 cursor-pointer select-none rounded;
}

.super-select-option:hover {
  @apply bg-teal-600;
}

.super-select-option.is-active,
.super-select-option.is-active:hover {
  @apply bg-teal-600 text-white;
}

/* Style the arrow inside the select element: */
.form-select:after {
  float: right;
  content: "";
  width: 0;
  height: 0;
  margin-top: 5px;
  border: 6px solid transparent;
  border-color: black transparent transparent transparent;
}

details:not(details[open])>.school-question-decoration:after {
  content: "﹀";
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
  align-items: center;
}

details[open]>.school-question-decoration:after {
  content: "︿";
  display: inline-block;
  margin-left: 10px;
  vertical-align: text-bottom;
  align-items: center;
}

@screen lg {
  .mobile-menu__button::before {
    content: attr(data-label);
    @apply text-orange-500 uppercase text-xs mr-4 font-bold block;
  }
}

.icon-grid-item__icon>svg {
  @apply w-20 h-20;
}
